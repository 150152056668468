import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { UserService } from './user/user.service';
import { LoginDto } from './interfaces/login-dto.interface';
import { ApiService } from '@red/api';

@Injectable()
export class AuthService {
  static AUTH = 'auth';
  static REFRESH_TOKEN = 'auth/access-token';
  private authenticated = false;

  /**
   * Constructor
   */
  constructor(@Inject(ApiService) private apiService: ApiService, private userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this.apiService.post('api/auth/forgot-account', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this.apiService.post('api/auth/reset-password', data);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<any> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this.apiService.post(AuthService.AUTH, credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<any> {
    // this.accessToken = '';
    if (AuthUtils.isTokenExpired(this.refreshToken)) {
      return of(false);
    }
    // Renew token
    return this.apiService.post(AuthService.REFRESH_TOKEN, { refreshToken: this.refreshToken }).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return true
        return of(true);
      }),
      catchError(() =>
        // Return false
        of(false)
      )
    );
  }
  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the tokens from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this.apiService.post('auth/sign-up', user);
  }

  /**
   * Forgot account
   *
   * @param data: { email: string; requestType: string }
   */
  forgot(data: { email: string; requestType: string }): Observable<any> {
    return this.apiService.post('auth/forgot-password', data);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }
    try {
      if (AuthUtils.isTokenExpired(this.accessToken)) {
        return this.signInUsingRefreshToken();
      }
    } catch (error) {
      this.signOut();
      return of(false);
    }
    // Check the access token expire date

    // console.log('check -> ',{authenticated:this.authenticated,accessToken:this.accessToken},AuthUtils.isTokenExpired(this.accessToken))

    // If the access token exists and it didn't expire, sign in using it
    return of(true);
  }

  // getUserInfoByToken():Observable<UserModel>{
  //   return this.apiService.get(AuthService.AUTH).pipe(
  //     map(res => UserModel.fromJson(res)));
  // }
}
