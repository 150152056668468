import { LOGIN_URL } from './constants/token';
import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService, @Inject(LOGIN_URL) private loginUrl: string, private router: Router, private activatedRoute: ActivatedRoute) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('source') !== 'ApiService') {
      return next.handle(req);
    }
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken && (!AuthUtils.isTokenExpired(this._authService.accessToken) || req.url.indexOf('access-token') !== -1)) {
      newReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
      });
    }
    // Response
    return next.handle(newReq).pipe(
      catchError(error => {
        console.log('error ->', error);
        // Catch "401 Unauthorized" responses
        if (((error instanceof HttpErrorResponse && error.status === 401) || error.statusCode === 401) && this.router.url.indexOf(this.loginUrl) === -1) {
          // Sign out
          this._authService.signOut();

          // Reload the app
          location.reload();
        } else if (error.status === 500 || error.statusCode === 500) {
          this.router.navigate(['error/500'], { skipLocationChange: true });
        }
        return throwError(() => error);
      })
    );
  }
}
