import { ENV_TYPE } from '@red/data-access';

export const environment = {
  baseUrl: 'https://huttons-hms-dev.redoc.co',
  portalUrl: 'https://huttons-portal-dev.azureedge.net',
  production: false,
  apiHost: 'https://huttons-api-gateway-dev.azure-api.net',
  storageMediaUrl: 'https://huttonsstoragedev.blob.core.windows.net',
  storageDrive: 'azure',
  apiPrefix: '/api/',
  appId: 'default',
  defaultTimeZone: 'Asia/Singapore',
  name: ENV_TYPE.DEV,
  sentryConfig: {
    // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options
    options: {
      debug: false,
      enabled: false,
      environment: "dev",
      dsn: "https://d89f7286188684d5d0cd869f7091793d@o434962.ingest.us.sentry.io/4507372675792896"
    }
  },
};
