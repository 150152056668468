
import * as Sentry from "@sentry/angular";
import { assign } from "lodash-es";
export function provideSentry(options: Sentry.BrowserOptions): void {
    const defaults: Sentry.BrowserOptions = {
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        enabled: true,

    }
    Sentry.init(assign(defaults, options));
}