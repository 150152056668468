import { BaseModel, Default, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { BLANK_FORM_STATUSES, PROJECT_PROPERTY_TYPES_OPTIONS } from '../data';
import { EAgentRole, EBlankFormReferralSourceType, EBlankFormStatus, EBlankFormTypeDetail, EManagementCommissionPdRole, EProjectType } from '../enums';
import { IBlankForm, IBlankFormRelatedAgent, IPersonalParticular, IStatusDescription } from '../interfaces';
import { KeysOfBlankFormHaveAgent, OptionDescription } from '../types';
import { BlankFormBdModel } from './blank-form-bd.model';
import { BlankFormBuyerInfoModel, BlankFormBuyerModel } from './blank-form-buyer.model';
import { BlankFormIcModel } from './blank-form-ic.model';
import { BlankFormOtherFeeModel } from './blank-form-other-fee.model';
import { BlankFormPdModel } from './blank-form-pd.model';
import { BlankFormReferralModel } from './blank-form-referral.model';
import { BlankFormRelatedAgentModel } from './blank-form-related-agent.model';
import { CurrencyRateModel } from './currency-rate.model';
import { GrossCommissionEarnedModel } from './gross-commission-earned.model';
import { ProjectCommissionMatrixModel } from './project-commission-matrix.model';
import { ProjectTransactionModel } from './project-transaction.model';
import { ProjectModel } from './project.model';
import { UnitModel } from './unit.model';
import { UpdatedByModel } from './updated-by.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';
import { EProjectPropertyType } from '../enums';
import { groupBy } from 'lodash-es';

export class BlankFormModel extends BaseModel implements IBlankForm {
  @Expose()
  address!: string;

  @Expose()
  code!: string;

  @Expose()
  internationalRef!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: string;

  @Expose()
  @Type(() => BlankFormBuyerInfoModel)
  buyer!: BlankFormBuyerInfoModel;

  @Expose()
  buyers!: BlankFormBuyerModel[];

  @Expose()
  @Default(GrossCommissionEarnedModel.createEmpty())
  @Type(() => GrossCommissionEarnedModel)
  grossComm!: GrossCommissionEarnedModel;

  @Expose()
  // @Default(GrossCommissionEarnedModel.createEmpty())
  @Type(() => GrossCommissionEarnedModel)
  hiGrossComm?: GrossCommissionEarnedModel;

  @Expose()
  // @Default(GrossCommissionEarnedModel.createEmpty())
  @Type(() => GrossCommissionEarnedModel)
  devGrossComm?: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  profomaGrossComm?: GrossCommissionEarnedModel;

  @Expose()
  id!: number;

  @Expose()
  optionDate!: string;

  @Expose()
  optionNo!: string;

  @Expose()
  eotDate!: string;

  @Expose()
  isReissue?: boolean;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  reissueDate?: string;

  @Expose()
  propertyType!: EProjectPropertyType;

  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => ProjectModel)
  project?: ProjectModel;

  @Expose()
  invoiceId?: number;

  @Expose()
  @Type(() => TaxInvoiceModel)
  invoice?: TaxInvoiceModel;

  @Expose()
  invoiceCode?: string;

  @Expose()
  @Transform(({ obj, value }) => {
    if (!value) {
      const paymentGroup = groupBy(obj?.payments, i => i?.code);
      return Object.values(paymentGroup).map((i) => {
        return i[0]?.paymentDate
      });
    }
    return value;
  })
  paymentDates!: string[];

  @Expose()
  @Type(() => ProjectCommissionMatrixModel)
  projectCommission!: ProjectCommissionMatrixModel;

  @Expose()
  @Transform(({ value }) => (value ? BlankFormRelatedAgentModel.fromJson(value) : BlankFormRelatedAgentModel.createEmpty()))
  relatedAgent!: IBlankFormRelatedAgent;

  get agentMain(): IPersonalParticular {
    return this.relatedAgent?.mains[0]?.agent ?? {}
  }

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  bonuses!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  overridings!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormIcModel)
  pools!: BlankFormIcModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormPdModel)
  pds!: BlankFormPdModel[];

  get pdOverride(): BlankFormPdModel[] {
    return this.pds?.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDOverride);
  }

  get pdReferral(): BlankFormPdModel[] {
    return this.pds?.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDReferral);
  }

  get pdManagement(): BlankFormPdModel[] {
    return this.pds?.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDManagement && !referral.additional);
  }

  get pd(): BlankFormPdModel[] {
    return this.pds?.filter(referral => referral.pdRole === EManagementCommissionPdRole.PDManagement && referral.additional === 1);
  }

  @Expose()
  @Default([])
  @Type(() => BlankFormReferralModel)
  referrals!: BlankFormReferralModel[];

  get referralsInternal(): BlankFormReferralModel[] {
    return this.referrals?.filter(referral => referral.type === EBlankFormReferralSourceType.internal);
  }

  get referralsExternal(): BlankFormReferralModel[] {
    return this.referrals?.filter(referral => referral.type === EBlankFormReferralSourceType.external);
  }

  @Expose()
  @Default([])
  @Type(() => BlankFormOtherFeeModel)
  otherFees!: BlankFormOtherFeeModel[];

  @Expose()
  @Default([])
  @Type(() => BlankFormBdModel)
  bds!: BlankFormBdModel[];

  @Expose()
  companyProfit!: number;

  @Expose()
  haTierProfit!: number;

  @Expose()
  agentTierProfit!: number;

  @Expose()
  companyTierProfit!: number;

  @Expose()
  remarks!: string;

  @Expose()
  transactedPrice!: number;

  @Expose()
  originalTransactedPrice?: number;

  @Expose()
  originalCurrencyId?: number;

  @Expose()
  @Type(() => CurrencyRateModel)
  originalCurrency?: CurrencyRateModel;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  transactionId?: number;

  @Expose()
  transactionCode?: string;

  @Expose()
  @Type(() => ProjectTransactionModel)
  transaction?: ProjectTransactionModel;

  @Expose()
  blankFormId?: number;

  @Expose()
  blankFormCode?: string;

  @Expose()
  @Type(() => BlankFormModel)
  blankForm?: BlankFormModel;

  @Expose()
  unitId!: number;

  @Expose()
  @Type(() => UnitModel)
  unit?: UnitModel;

  @Expose()
  unitBlkNo?: string;

  @Expose()
  unitPostalCode?: string;

  @Expose()
  unitNoOfRoom?: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  migrationId!: number;

  @Expose()
  @Default(EBlankFormStatus.draft)
  status!: EBlankFormStatus;

  @Expose()
  rejectReason!: string | null;

  @Expose()
  @Type(() => Number)
  hiProfitAmount!: number;

  @Expose()
  userAgentId!: number;

  @Expose()
  role!: EAgentRole;

  @Expose()
  landAreaSqft!: number;

  @Expose()
  landAreaSqm!: number;

  get propertyTypeInfo(): OptionDescription<EProjectPropertyType> | null {
    // return this.propertyType ? PROJECT_TRANSACTION_PROPERTY_TYPE_OPTIONS[this.propertyType] : null;
    return this.project?.type ? PROJECT_PROPERTY_TYPES_OPTIONS[this.project?.type as EProjectPropertyType] : null;
  }

  get statusDisplay(): IStatusDescription {
    return BLANK_FORM_STATUSES[this.status];
  }

  get blankFormType(): EBlankFormTypeDetail {
    if (this.blankFormId) {
      return EBlankFormTypeDetail.blankFormAdjustment;
    }
    if (this.transactionId) {
      return EBlankFormTypeDetail.transactionAdjustment;
    }
    return EBlankFormTypeDetail.normal;
  }

  get canSubmit(): boolean {
    return BLANK_FORM_STATUSES[this.status].canSubmit ?? false;
  }

  get canEdit(): boolean {
    return (
      (!this.transactionId && !this.blankFormId && (this.project?.entity === EProjectType.INTERNATIONAL && this.status === EBlankFormStatus.approved) // This is a special case 
        ? true
        : BLANK_FORM_STATUSES[this.status].canEdit) ?? false
    );
  }

  @Expose()
  @Transform(({ obj }) => {
    return true;
  })
  canEditRemarks!: boolean;

  get canDelete(): boolean {
    return BLANK_FORM_STATUSES[this.status].canDelete ?? false;
  }

  getIdsPersonExisting(agentType: KeysOfBlankFormHaveAgent): number[] {
    const idsPerson: number[] = [];
    switch (agentType) {
      case 'mains':
      case 'internals':
      case 'externals':
      case 'leaders':
        this.relatedAgent[agentType]?.forEach(agent => {
          agent.agentId && idsPerson.push(agent.agentId);
        });
        return idsPerson;
    }
    this[agentType].forEach(ic => {
      ic.salespersonId && idsPerson.push(ic.salespersonId);
    });
    return idsPerson;
  }

  get canAgentSubmit() {
    return BLANK_FORM_STATUSES[this.status].canAgentSubmit && this.role === EAgentRole.IC;
  }

  get canAgentEdit() {
    return BLANK_FORM_STATUSES[this.status].canAgentEdit && this.role === EAgentRole.IC;
  }

  get canAgentViewRejectReason() {
    return this.role === EAgentRole.IC;
  }


}
