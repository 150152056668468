import { ECreditNoteStatus, EPaymentReversalStatus, EReceiptStatus, EResaleAdjustmentStatus, EResalePropertyType, EResaleStatus, EResaleType } from '../enums';
import { VerifiedByModel } from '../models';
import { EClientType, EPaymentAdvice } from './../enums/resale.enum';

export interface IResaleSearchEngineReceivedPayment {
  id: number;
  code: string;
  receiptDate: string;
  reversalDate: string;
  totalAmount: number;
  reference: string;
  status: EReceiptStatus | EPaymentReversalStatus;
  businessUnitId: number;
  remarks: string;
}

export interface IResaleSearchEngineExternal {
  contactId: number;
  contactCode: string;
  contactName: string;
  invoiceId?: number;
  invoiceDeletedAt: Date | null;
  role: string;
  subTotalAmount: number;
  totalAmount: number;
  taxAmount: number;
}

export interface IResaleSearchEngineAdjExternal {
  resaleAdjId: number;
  invoiceId: number | null;
  resaleAdjStatus: EResaleAdjustmentStatus;
  resaleAdjCode: string;
  resaleAdjExternalContactId: number;
}

export interface IResaleSearchEngineInternal {
  agentId: number;
  role: string;
}

export interface IResaleSearchEngineCreditNote {
  // businessUnitId: number;
  code: string;
  creditNoteDate: string;
  id: number;
  remarks: string;
  status: ECreditNoteStatus;
  subTotalAmount: number;
  taxAmount: number;
  totalAmount: number;
}

export interface IResaleSearchEngine {
  address: string | null;
  isInternal: boolean;
  agreementDate: string | null;
  billingAttention: string;
  billingClientCode: string;
  billingClientName: string;
  billingClientType: `${EClientType}`;
  billingPaymentAdvice: `${EPaymentAdvice}`;
  billingDate: string | null;
  billingEnvDelivery: number;
  code: string;
  commencementDate: string | null;
  completionDate: string | null;
  createdAt: string;
  creditNotes: IResaleSearchEngineCreditNote[];
  deletedAt: string | null;
  expireDate: string | null;
  externalId: number | null;
  externalRole: string | null;
  externals?: IResaleSearchEngineExternal[];
  externalsSize: number;
  id: number;
  internals?: IResaleSearchEngineInternal[];
  internalsSize: number;
  invoiceBalanceDue: number;
  invoiceCode: string;
  invoiceDate: string | null;
  invoiceId: number;
  invoiceIsCancel: boolean;
  invoiceIsWriteOff: boolean;
  invoicePaidAmount: number;
  invoiceTotal: number;
  name: string | null;
  optionDate: string | null;
  propertyAddress: string;
  propertyType: EResalePropertyType;
  receiptCode: string;
  receiptDate: string | null;
  receiptId: number | null;
  receiptReference: string;
  receiptTotalAmount: number | null;
  receipts: IResaleSearchEngineReceivedPayment[];
  resaleAdjExternals: IResaleSearchEngineAdjExternal[];
  resaleType: EResaleType;
  reversals: IResaleSearchEngineReceivedPayment[];
  salespersonBusinessName: string;
  salespersonCeaRegNo: string | null;
  salespersonId: number;
  salespersonNricName: string;
  salespersonRole: string;
  status: EResaleStatus;
  subTotalAmount: number;
  taxAmount: number;
  totalAmount: number;
  submissionDate: string | null;
  totalCommission: number | null;
  updatedAt: string;
  showUpdatedBy: boolean;
  verifiedBy?: {
    id: number;
    name: string;
  };
}
