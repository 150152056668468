import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, of, switchMap } from 'rxjs';
import { DeveloperAddressApiService, MetadataApiService, ProjectApiService } from '@cms/apis';
import { DeveloperAction } from '../actions';

@Injectable()
export class DeveloperEffects {
  totalUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeveloperAction.getAddressDefault),
      mergeMap(action =>
        this.devAddressApiService.getAddressDefaultByDeveloperId(action.developerId).pipe(
          map(data => DeveloperAction.getAddressDefaultSuccess({ developerId: action.developerId, data })),
          catchError(error => of(DeveloperAction.getAddressDefaultFailure({ error })))
        )
      )
    );
  });
  constructor(private actions$: Actions, private devAddressApiService: DeveloperAddressApiService) {}
}
