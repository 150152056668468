import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ProfitCentresApiService } from '@cms/apis';
import { ProfitCenterAction } from '../actions';

@Injectable()
export class ProfitCenterEffects {
  path$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfitCenterAction.getPath),
      mergeMap(action =>
        this._profitCentresApiService.getPath(action.profitItemId).pipe(
          map(data => {
            const paths = new Array<string>();
            data.forEach(value => {
              paths.push(value.name);
            });
            return ProfitCenterAction.getPathSuccess({
              profitItemId: action.profitItemId,
              result: data,
              pathFull: paths.join('/ '),
            });
          }),
          catchError(error => of(ProfitCenterAction.countMemberFailure({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private _profitCentresApiService: ProfitCentresApiService) {}
}
