import { BaseModel, Default, If } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EPaymentGeneratorStatus } from '../enums';
import { IHoverable, IMemoPayable, IPaymentGenerator, IPaymentGeneratorFilterRecord, IPaymentGeneratorSummary, IPaymentMethodData } from '../interfaces';
import { PaymentGeneratorPaymentMode } from '../types/payment-generator.type';
import { BankAccountModel } from './bank-account.model';
import { BaseFixedModel } from './base.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { InvoicePayableModel } from './invoice-payable.model';
import { LedgerAccountModel } from './ledger-account.model';
import { MemoPayableModel } from './memo-payable.model';
import { ProjectTransactionModel } from './project-transaction.model';
import { SupplierCreditNoteModel } from './supplier-credit-note-enhanced.model';
import { SupplierInvoiceModel } from './supplier-invoice-enhanced.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';
export class PaymentMethodDataModel extends BaseModel implements IPaymentMethodData {
  @Expose()
  paymentMode!: PaymentGeneratorPaymentMode;

  @Expose()
  bankAccountId!: number;

  @Expose()
  @Type(() => BankAccountModel)
  bankAccount!: BankAccountModel;

  @Expose()
  // @Type(() => Date)
  // submissionDate!: Date;
  submissionDate!: string;

  @Expose()
  // @Type(() => Date)
  // valueDate!: Date;
  valueDate!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  description!: string;

  @Expose()
  chequeNumberFrom!: string;

  get invalid(): boolean {
    if (!this.paymentMode) {
      return true;
    }
    if (typeof this.bankAccountId !== 'number') {
      return true;
    }
    // if (this.paymentMode === 'Cheque' && typeof this.chequeNumberFrom !== 'string') {
    //   return true;
    // }
    // if (this.paymentMode === 'Giro' && !(this.submissionDate instanceof Date) && !(this.valueDate instanceof Date)) {
    //   return true;
    // }
    if (this.paymentMode === 'Giro' && !this.submissionDate && !this.valueDate) {
      return true;
    }
    return false;
  }
}

export class PaymentGeneratorSummaryModel extends BaseModel implements IPaymentGeneratorSummary {
  @Expose()
  totalSelected!: number;
}

export class TaxInvoicePaymentGeneratorModel extends BaseModel implements IHoverable {
  // @Expose()
  // index!: number;

  @Expose()
  isHover?: boolean;

  @Expose()
  id!: number;

  @Expose()
  amount!: number;

  @Expose()
  invoiceDate!: string;

  @Expose()
  invoiceNumber!: string;

  @Expose()
  total!: number;

  @Expose()
  receipt!: {
    receiptNumber: string;
    receiptDate: string;
    amount: number;
    description: string;
    cheque: string;
    code: string;
  };

  @Expose()
  receiptDetailId!: number;
}

export class TransactionPaymentGeneratorModel extends BaseModel implements IHoverable {
  // @Expose()
  // index!: number;

  @Expose()
  isHover?: boolean;

  // @Expose()
  // id!: number;

  @Expose()
  amount!: number;

  @Expose()
  code!: string;

  @Expose()
  description!: string;

  @Expose()
  receipt!: {
    receiptNumber: string;
    receiptDate: string;
    amount: number;
    description: string;
    cheque: string;
    code: string;
  };

  @Expose()
  transactionReceiptDetailId!: number[];
}

export class PayeePaymentGeneratorModel extends BaseModel implements IHoverable {
  // @Expose()
  // index!: number;

  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  isHover?: boolean;
}

export class SupplierInvoicePaymentGeneratorErrorModel extends BaseModel {
  @Expose()
  clearFate!: number;

  @Expose()
  errorCode!: string;

  @Expose()
  errorMessage!: string;

  @Expose()
  id!: number;

  @Expose()
  paymentGeneratorId!: number;

  @Expose()
  supplier!: {
    id: number;
    name: string;
    code: string;
  };

  @Expose()
  supplierCode!: string;

  @Expose()
  supplierId!: number;

  @Expose()
  supplierInvoiceDetailId!: number

  @Expose()
  transactionNo!: string;

}

export class SupplierInvoicePaymentGeneratorModel extends BaseModel implements IHoverable {
  @Expose()
  isHover?: boolean;

  @Expose()
  id!: number;

  @Expose()
  amount!: number;

  @Expose()
  invoiceDate!: string;

  @Expose()
  invoiceNumber!: string;

  @Expose()
  description!: number;

  @Expose()
  total!: number;

  @Expose()
  contact!: {
    id: number;
    name: string;
    code: string;
  };
}

export class SupplierCreditNotePaymentGeneratorModel extends BaseModel implements IHoverable {
  @Expose()
  isHover?: boolean;

  @Expose()
  id!: number;

  @Expose()
  amount!: number;

  @Expose()
  total!: number;

  @Expose()
  paidAmount!: number;

  @Expose()
  invoiceDate!: string;

  @Expose()
  invoiceNumber!: string;

  @Expose()
  balanceDue!: number;

  @Expose()
  contact!: {
    id: number;
    name: string;
    code: string;
  };
}

export class FilterMetaDataModel extends BaseModel {
  @Expose()
  @Type(() => TaxInvoicePaymentGeneratorModel)
  @Default([])
  includeTaxInvoices!: TaxInvoicePaymentGeneratorModel[];

  @Expose()
  @Type(() => TaxInvoicePaymentGeneratorModel)
  @Default([])
  excludeTaxInvoices!: TaxInvoicePaymentGeneratorModel[];

  @Expose()
  @Type(() => TransactionPaymentGeneratorModel)
  @Default([])
  includeTransactions!: TransactionPaymentGeneratorModel[];

  @Expose()
  @Type(() => TransactionPaymentGeneratorModel)
  @Default([])
  excludeTransactions!: TransactionPaymentGeneratorModel[];

  @Expose()
  @Type(() => PayeePaymentGeneratorModel)
  @Default([])
  excludePayees!: PayeePaymentGeneratorModel[];

  @Expose()
  @Type(() => SupplierInvoicePaymentGeneratorModel)
  @Default([])
  excludeSupplierInvoices!: SupplierInvoicePaymentGeneratorModel[];

  @Expose()
  @Type(() => SupplierCreditNotePaymentGeneratorModel)
  @Default([])
  excludeSupplierCreditNotes!: SupplierCreditNotePaymentGeneratorModel[];
}

export class PaymentGeneratorModel extends BaseFixedModel implements IPaymentGenerator {
  @Expose()
  @Type(() => PaymentMethodDataModel)
  paymentMethodData!: PaymentMethodDataModel;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  bankReturnFile!: string;

  @Expose()
  // @Type(() => Date)
  // receiptDateFrom!: Date;
  receiptDateFrom!: string;

  @Expose()
  // @Type(() => Date)
  // receiptDateTo!: Date;
  receiptDateTo!: string;

  @Expose()
  // @Type(() => Date)
  // paymentDate!: Date;
  paymentDate!: string;

  @Expose()
  includeAdjustment!: boolean;

  @Expose()
  includeMiscCreditNote!: boolean;

  @Expose()
  payProfitSharingonly!: boolean;

  @Expose()
  payNormalCommissionOnly!: boolean;

  @Expose()
  excludePartialReceipt!: boolean;

  @Expose()
  includeMiscLinkAr!: boolean;

  @Expose()
  autoRunDataCompute!: boolean;

  @Expose()
  profitSharingCommmission!: boolean;

  @Expose()
  excludeSupplierInvoiceIds!: number[];

  @Expose()
  @Default([])
  @Type(() => SupplierInvoiceModel)
  excludeSupplierInvoices!: SupplierInvoiceModel[];

  @Expose()
  @Default([])
  excludePayeeIds!: number[];

  @Expose()
  @Default([])
  @Type(() => ContactCustomerAndSupplierModel)
  excludePayees!: ContactCustomerAndSupplierModel[];

  @Expose()
  @Default([])
  excludeTransactionCodes!: string[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionModel)
  excludeTransactions!: ProjectTransactionModel[];

  @Expose()
  @Default([])
  includeTransactionCodes!: number[];

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionModel)
  includeTransactions!: ProjectTransactionModel[];

  @Expose()
  @Default([])
  includeTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  @Type(() => TaxInvoiceModel)
  includeTaxInvoices!: TaxInvoiceModel[];

  @Expose()
  @Default([])
  excludeTaxInvoiceIds!: number[];

  @Expose()
  @Default([])
  @Type(() => TaxInvoiceModel)
  excludeTaxInvoices!: TaxInvoiceModel[];

  @Expose()
  @Default([])
  excludeSupplierCreditNoteIds!: number[];

  @Expose()
  @Default([])
  @Type(() => SupplierCreditNoteModel)
  excludeSupplierCreditNote!: SupplierCreditNoteModel[];

  @Expose()
  @Type(() => FilterMetaDataModel)
  @Default({
    includeTaxInvoices: [],
    excludeTaxInvoices: [],
    includeTransactions: [],
    excludeTransactions: [],
    excludePayees: [],
    excludeSupplierInvoices: [],
    excludeSupplierCreditNotes: [],
  })
  filterMetaData!: FilterMetaDataModel;

  @Expose()
  @If([
    [obj => !!obj.includeTransactionCodes?.length && !!obj.excludeTransactionCodes?.length, 'Include, Exclude'],
    [obj => !!obj.includeTransactionCodes?.length, 'Include'],
    [obj => !!obj.excludeTransactionCodes?.length, 'Exclude'],
  ])
  transactionFilterState!: string;

  @Expose()
  // @Type(() => Date)
  // selectedDate!: Date;
  selectedDate!: string;

  @Expose()
  // @Type(() => Date)
  // computatedDate!: Date;
  computatedDate!: string;

  @Expose()
  // @Type(() => Date)
  // createdDate!: Date;
  createdDate!: string;

  @Expose()
  // @Type(() => Date)
  // updatedDate!: Date;
  updatedDate!: string;

  @Expose()
  status!: EPaymentGeneratorStatus;

  @Expose()
  @Transform(({ obj }) => {
    const validPaymentMethod = obj.paymentMethodData?.paymentMode === 'Giro';
    const validStatus = [EPaymentGeneratorStatus.Assigned, EPaymentGeneratorStatus.EmailSent, EPaymentGeneratorStatus.Posted].includes(obj.status);

    return validPaymentMethod && validStatus ? 'Yes' : 'No';
  })
  emailSent!: string;

  @Expose()
  @Transform(({ obj }: { obj: IPaymentGenerator }) => {
    switch (obj.status) {
      case EPaymentGeneratorStatus.Selected:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: 'selected',
        };

      case EPaymentGeneratorStatus.Computed:
        return {
          className: 'status-box-purple',
          value: obj.status,
          displayName: 'computed',
        };

      case EPaymentGeneratorStatus.EmailSent:
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Email Sent',
        };

      case EPaymentGeneratorStatus.Assigned:
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'assigned',
        };

      case EPaymentGeneratorStatus.Posted:
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: 'posted',
        };
      default:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: obj.status,
        };
    }
  })
  statusObj!: { className: string; value: string; displayName: string };

  @Expose()
  @Transform(({ obj }) => {
    return [EPaymentGeneratorStatus.Selected].includes(obj.status);
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return [EPaymentGeneratorStatus.Selected].includes(obj.status);
  })
  canCompute!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return [EPaymentGeneratorStatus.Selected, EPaymentGeneratorStatus.Computed].includes(obj.status);
  })
  canEditPaymentMethod!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return ![EPaymentGeneratorStatus.Selected].includes(obj.status);
  })
  canViewPreBillingReport!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return obj.paymentMethodData?.paymentMode === 'Giro' && ![EPaymentGeneratorStatus.Selected].includes(obj.status);
  })
  canViewBankSubmissionFileReport!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return obj.paymentMethodData?.paymentMode === 'Giro' && ![EPaymentGeneratorStatus.Selected].includes(obj.status);
  })
  canDownloadBankSubmissionFile!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return obj.paymentMethodData?.paymentMode === 'Giro' && [EPaymentGeneratorStatus.Computed, EPaymentGeneratorStatus.Assigned].includes(obj.status);
  })
  canConfirmEmail!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return [EPaymentGeneratorStatus.Selected, EPaymentGeneratorStatus.Computed, EPaymentGeneratorStatus.Assigned, EPaymentGeneratorStatus.EmailSent].includes(
      obj.status
    );
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return (
      [EPaymentGeneratorStatus.EmailSent].includes(obj.status) ||
      ([EPaymentGeneratorStatus.Computed].includes(obj.status) && obj.paymentMethodData?.paymentMode === 'Cheque')
    );
  })
  canPostPayment!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return obj.paymentMethodData?.paymentMode;
  })
  paymentMethodAdded!: boolean;

  @Expose()
  @Transform(({ obj }) => obj.status === EPaymentGeneratorStatus.Posted)
  canViewPostPaymentReport!: boolean;

  @Expose()
  @Transform(({ obj }) => obj.paymentMethodData?.paymentMode === 'Giro')
  payByGiro!: boolean;
}

export class PaymentGeneratorFilterRecordModel extends BaseModel implements IPaymentGeneratorFilterRecord {
  @Expose()
  @Type(() => PaymentGeneratorModel)
  paymentGenerator!: PaymentGeneratorModel;

  @Expose()
  @Type(() => MemoPayableModel)
  supplierInvoice!: IMemoPayable;

  @Expose()
  @Type(() => InvoicePayableModel)
  taxInvoice!: InvoicePayableModel;

  @Expose()
  @Type(() => ProjectTransactionModel)
  transaction!: ProjectTransactionModel;
}
