export enum EBlankFormStatus {
  draft = 'draft',
  submitted = 'submitted',
  rework1 = 'rework1',
  confirmed = 'confirmed',
  rework2 = 'rework2',
  verified = 'verified',
  approved = 'approved',
  invoiced = 'invoiced',
  rejected = 'rejected',
  completed = 'completed',
}

export enum EBlankFormTypeDetail {
  blankFormAdjustment = 'blankFormAdjustment',
  transactionAdjustment = 'transactionAdjustment',
  normal = 'normal',
}

export enum EBlankFormType {
  adjustment = 'adjustment',
  normal = 'normal',
}

export enum EBlankFormIcType {
  bonus = 'bonus',
  overriding = 'overriding',
  pool = 'pool',
}

export enum EBlankFormReferralSourceType {
  internal = 'internal',
  external = 'external',
}

export enum EBlankFormReferralType {
  pdOverride = 'pdOverride',
  pdReferral = 'pdReferral',
  pdManagement = 'pdManagement',
  refFormulaA = 'refFormulaA',
  refFormulaB = 'refFormulaB',
  refFormulaC = 'refFormulaC',
  refFormulaD = 'refFormulaD',
  companyComm = 'companyComm',
  otherFee = 'otherFee',
}

export enum EBlankFormInternalAgentType {
  internalCobroke = 'internalCobroke',
  tagger = 'tagger',
  internalCobrokeIncentive = 'internalCobrokeIncentive',
  taggerIncentive = 'taggerIncentive',
  icIncentive = 'icIncentive',
}

export enum EBLankFormReferralFormula {
  refFormulaA = 'refFormulaA',
  refFormulaB = 'refFormulaB',
  refFormulaC = 'refFormulaC',
  refFormulaD = 'refFormulaD',
}

export enum EBlankFormListViewType {
  all = 'all',
  verification = 'verification',
  approval = 'approval',
  invoice = 'invoice',
}

export enum EBlankFormOtherFeeType {
  ultimateProfit = 'ultimateProfit',
  marketingFund = 'marketingFund',
  mf = 'MF',
  // up = 'UP',
}

export enum EBlankFormListApiSearch {
  search = 'search',
  searchVerification = 'searchVerification',
  searchApproval = 'searchApproval',
  searchInvoice = 'searchInvoice',
  searchAdjustmentVerification = 'searchAdjustmentVerification',
  searchAdjustmentApproval = 'searchAdjustmentApproval'
}
export enum EBlankFormApiConfirm {
  Verification = 'Verification',
  Approval = 'Approval',
  Invoice = 'Invoice',
  AdjVerification = 'AdjVerification',
  AdjApproval = 'AdjApproval'
}