import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PromotionApprovalModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { PromotionApprovalAction } from '../actions';
import { PromotionApprovalReducer } from '../reducers';
import { PromotionApprovalSelector } from '../selectors';

@Injectable()
export class PromotionApprovalFacade {
  selectedItem$ = this._store.select(PromotionApprovalSelector.selectPromotionApproval);
  constructor(private _store: Store<PromotionApprovalReducer.PromotionApprovalState>) {}

  /**
   * Get @PromotionApprovalModel by id
   */
  getById(id: number): Observable<PromotionApprovalModel | null> {
    this._store.dispatch(PromotionApprovalAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<PromotionApprovalModel | null>;
  }
}
