import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { ProjectTransactionBuyerDto, ProjectTransactionCreateClonedDto, ProjectTransactionCreateDto, ProjectTransactionUpdateDto } from '@shared/data-access/dto';
import { IProjectTransaction, IProjectTransactionBds, IProjectTransactionBuyer, IProjectTransactionPool } from '@shared/data-access/interfaces';
import {
  ProjectSearchEngineModel,
  ProjectTransactionAgentInfoSummaryModel,
  ProjectTransactionBdsModel,
  ProjectTransactionBonusModel,
  ProjectTransactionBuyerModel,
  ProjectTransactionCompanyInfoSummaryModel,
  ProjectTransactionModel,
  ProjectTransactionOverridingModel,
  ProjectTransactionPdModel,
  ProjectTransactionPoolModel,
  ProjectTransactionReferralModel,
  ProjectTransactionSummaryModel,
  UnitModel,
} from '@shared/data-access/models';
import { map, Observable } from 'rxjs';
import { ProjectTransactionOtherModel } from '@shared/data-access/models/project-transaction-other.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectTransactionApiService {
  static BDS_POINT = 'transaction/transaction-bds';
  static PDS_POINT = 'transaction/transaction-pds';
  static POOL_POINT = 'transaction/transaction-pools';
  static ROOT_POINT = 'transaction/transactions';
  static BONUS_POINT = 'transaction/transaction-bonuses';
  static BUYER_POINT = 'transaction/transaction-buyers';
  static OTHERS_POINT = 'transaction/transaction-others';
  static REWORK_POINT = 'transaction/transactions/:id/rework';
  static SUBMIT_POINT = 'transaction/transactions/:id/submit';
  static CONFIRM_POINT = 'transaction/transactions/:id/action';
  static REWORKS_POINT = 'transaction/transactions/rework';
  static SUBMITS_POINT = 'transaction/transactions/submit';
  static CONFIRMS_POINT = 'transaction/transactions/action';
  static FOR_BATCH_POINT = 'transaction/transactions/for-batch';
  static REFERRALS_POINT = 'transaction/transaction-referrals';
  static OVERRIDING_POINT = 'transaction/transaction-overridings';
  static SUMMARY_AGENT_POINT = 'transaction/transactions/summary-agent';
  static UNIT_FOR_SALE_POINT = 'transaction/units/for-sale';
  static SEARCH_ENGINE_POINT = 'transaction/engine/transactions';
  static SEARCH_ENGINE_FINANCE_POINT = 'transaction/engine/finance/transactions';
  static SUMMARY_COMPANY_POINT = 'transaction/transactions/summary-company';
  static STATUS_HISTORIES_POINT = 'transaction/status-histories';
  static SUMMARY_POINT = 'transaction/transactions/summary';

  constructor(private apiService: ApiService) { }

  create(data: ProjectTransactionCreateDto | ProjectTransactionCreateClonedDto, option?: IApiOption): Observable<ProjectTransactionModel> {
    return this.apiService
      .post(`${ProjectTransactionApiService.ROOT_POINT}`, data, option)
      .pipe(map((res: IProjectTransaction) => ProjectTransactionModel.fromJson(res) as ProjectTransactionModel));
  }

  get(itemId: number): Observable<ProjectTransactionModel> {
    return this.apiService
      .get(ProjectTransactionApiService.ROOT_POINT + '/' + itemId)
      .pipe(map(res => ProjectTransactionModel.fromJson(res) as ProjectTransactionModel));
  }

  update(id: number, data: ProjectTransactionUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectTransactionApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.ROOT_POINT, query, { ...option, removeEmpty: { enable: true } })
      .pipe(map(data => new PaginationAdapter(ProjectTransactionModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectTransactionApiService.ROOT_POINT + '/' + id, option);
  }

  searchStatusHistories(query = {}, option?: IApiOption): Observable<PaginationAdapter<any>> {
    return this.apiService
      .get(ProjectTransactionApiService.STATUS_HISTORIES_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionModel, data)));
  }

  submit(id: number, relatedAgent = {} || null): Observable<{ data: IProjectTransaction; statusCode: string }> {
    return this.apiService.post(ProjectTransactionApiService.SUBMIT_POINT, { id, data: {}, relatedAgent }, { excludeFields: [''] });
  }

  multiSubmit(transactions: { id: number }[]): Observable<unknown> {
    return this.apiService.post(ProjectTransactionApiService.SUBMIT_POINT, { transactions }, { excludeFields: [''] });
  }

  rework(id: number, reason: string): Observable<unknown> {
    return this.apiService.post(ProjectTransactionApiService.REWORK_POINT, { id, data: { reason } }, { excludeFields: [''] });
  }

  reworks(transactions: { id: number; data: { reason: string } }[]): Observable<unknown> {
    return this.apiService.post(ProjectTransactionApiService.REWORKS_POINT, { transactions }, { excludeFields: [''] });
  }

  confirm(id: number): Observable<unknown> {
    return this.apiService.post(ProjectTransactionApiService.CONFIRM_POINT, { id, data: {} }, { excludeFields: [''] });
  }

  confirms(transactions: { id: number }[]): Observable<unknown> {
    transactions = transactions.map(item => ({ id: item.id, data: {} }));
    return this.apiService.post(ProjectTransactionApiService.CONFIRMS_POINT, { transactions }, { excludeFields: [''] });
  }

  createBonus(data: any): Observable<ProjectTransactionBonusModel> {
    return this.apiService.post(`${ProjectTransactionApiService.BONUS_POINT}/setup`, data);
    // .pipe(
    //     map(
    //         (res: IProjectTransaction) => ProjectTransactionBonusModel.fromJson(res) as ProjectTransactionBonusModel
    //     )
    // );
  }

  previewBonus(data: { projectId: number; projectCommissionId: number; optionDate: string }): Observable<ProjectTransactionBonusModel[]> {
    return (
      this.apiService
        .post(`${ProjectTransactionApiService.BONUS_POINT}/preview`, data)
        // .pipe(map((res: IProjectTransaction[]) => res.map(item => ProjectTransactionBonusModel.fromJson(item) as ProjectTransactionBonusModel)));
        .pipe(map((res: IProjectTransaction[]) => res.map(item => ProjectTransactionBonusModel.fromJson(item, { groups: ['full'] }) as ProjectTransactionBonusModel)))
    );
  }

  getBonus(itemId: number): Observable<ProjectTransactionBonusModel> {
    return this.apiService
      .get(ProjectTransactionApiService.BONUS_POINT + '/' + itemId)
      .pipe(map(res => ProjectTransactionBonusModel.fromJson(res) as ProjectTransactionBonusModel));
  }

  updateBonus(id: number, data: ProjectTransactionUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectTransactionApiService.BONUS_POINT + '/' + id, data, { excludeFields: [''] });
  }

  searchBonus(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionBonusModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.BONUS_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionBonusModel, data, ['full'])));
  }

  searchBonusByGroups(query = {}, groups: string[], option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionBonusModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.BONUS_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionBonusModel, data, groups)));
  }

  deleteBonus(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectTransactionApiService.BONUS_POINT, { ids }, option);
  }

  // Overriding
  createOverriding(data: any): Observable<ProjectTransactionOverridingModel> {
    return this.apiService
      .post(`${ProjectTransactionApiService.OVERRIDING_POINT}/setup`, data)
      .pipe(map((res: IProjectTransaction) => ProjectTransactionOverridingModel.fromJson(res) as ProjectTransactionOverridingModel));
  }

  getOverriding(itemId: number): Observable<ProjectTransactionOverridingModel> {
    return this.apiService
      .get(ProjectTransactionApiService.OVERRIDING_POINT + '/' + itemId)
      .pipe(map(res => ProjectTransactionOverridingModel.fromJson(res) as ProjectTransactionOverridingModel));
  }

  previewOverridings(data: { projectId: number; projectCommissionId: number; optionDate: string }): Observable<ProjectTransactionOverridingModel[]> {
    return (
      this.apiService
        .post(`${ProjectTransactionApiService.OVERRIDING_POINT}/preview`, data)
        // .pipe(map((res: IProjectTransaction[]) => res.map(item => ProjectTransactionOverridingModel.fromJson(item) as ProjectTransactionOverridingModel)));
        .pipe(
          map((res: IProjectTransaction[]) =>
            res.map(item => ProjectTransactionOverridingModel.fromJson(item, { groups: ['full'] }) as ProjectTransactionOverridingModel)
          )
        )
    );
  }

  updateOverriding(id: number, data: ProjectTransactionUpdateDto): Observable<any> {
    return this.apiService.patch(ProjectTransactionApiService.OVERRIDING_POINT + '/' + id, data, { excludeFields: [''] });
  }

  searchOverriding(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionOverridingModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.OVERRIDING_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionOverridingModel, data)));
  }

  deleteOverriding(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectTransactionApiService.OVERRIDING_POINT, { ids }, option);
  }

  // Pool
  searchPools(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionPoolModel>> {
    return this.apiService.get(ProjectTransactionApiService.POOL_POINT, query, option).pipe(map(data => new PaginationAdapter(ProjectTransactionPoolModel, data)));
  }

  searchPoolsByGroups(query = {}, groups: string[], option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionPoolModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.POOL_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionPoolModel, data, groups)));
  }

  previewPools(data: { projectId: number; projectCommissionId: number; optionDate: string }): Observable<ProjectTransactionPoolModel[]> {
    return this.apiService
      .post(`${ProjectTransactionApiService.POOL_POINT}/preview`, data)
      .pipe(map((res: any[]) => res.map(item => ProjectTransactionPoolModel.fromJson(item) as ProjectTransactionPoolModel)));
  }

  createPool(data: any): Observable<ProjectTransactionPoolModel> {
    return this.apiService
      .post(`${ProjectTransactionApiService.POOL_POINT}/setup`, data)
      .pipe(map((res: IProjectTransactionPool) => ProjectTransactionPoolModel.fromJson(res) as ProjectTransactionPoolModel));
  }

  // buyer
  searchBuyers(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionBuyerModel>> {
    return this.apiService.get(ProjectTransactionApiService.BUYER_POINT, query, option).pipe(map(data => new PaginationAdapter(ProjectTransactionBuyerModel, data)));
  }

  getBuyer(itemId: number): Observable<ProjectTransactionBuyerModel> {
    return this.apiService
      .get(ProjectTransactionApiService.BUYER_POINT + '/' + itemId)
      .pipe(map(res => ProjectTransactionBuyerModel.fromJson(res) as ProjectTransactionBuyerModel));
  }

  createBuyer(data: { isOverride: boolean; transactionId: number; data: ProjectTransactionBuyerDto[] }): Observable<ProjectTransactionBuyerModel> {
    return this.apiService
      .post(`${ProjectTransactionApiService.BUYER_POINT}/setup`, data)
      .pipe(map((res: IProjectTransactionBuyer) => ProjectTransactionBuyerModel.fromJson(res) as ProjectTransactionBuyerModel));
  }

  deleteBuyers(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(ProjectTransactionApiService.BUYER_POINT, { ids }, option);
  }

  // summary
  getAgentSummary(id: number): Observable<ProjectTransactionAgentInfoSummaryModel> {
    return this.apiService.get(`${ProjectTransactionApiService.SUMMARY_AGENT_POINT}/${id}`).pipe(map(data => ProjectTransactionAgentInfoSummaryModel.fromJson(data)));
  }

  getCompanySummary(id: number): Observable<ProjectTransactionCompanyInfoSummaryModel> {
    return this.apiService
      .get(`${ProjectTransactionApiService.SUMMARY_COMPANY_POINT}/${id}`)
      .pipe(map(data => ProjectTransactionCompanyInfoSummaryModel.fromJson(data)));
  }

  // unit
  searchUnits(type: 'forSale', query = {}, option?: IApiOption): Observable<PaginationAdapter<UnitModel>> {
    const endPoint = ProjectTransactionApiService.UNIT_FOR_SALE_POINT;
    return this.apiService.get(endPoint, query, option).pipe(map(data => new PaginationAdapter(UnitModel, data)));
  }

  searchForBatch(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionModel>> {
    return this.apiService.get(ProjectTransactionApiService.FOR_BATCH_POINT, query, option).pipe(map(data => new PaginationAdapter(ProjectTransactionModel, data)));
  }

  searchPds(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionPdModel>> {
    return this.apiService.get(ProjectTransactionApiService.PDS_POINT, query, option).pipe(map(data => new PaginationAdapter(ProjectTransactionPdModel, data)));
  }

  searchReferrals(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionReferralModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.REFERRALS_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectTransactionReferralModel, data)));
  }

  searchOthers(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionOtherModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.OTHERS_POINT, query, option)
      .pipe(map(data => new PaginationAdapter<ProjectTransactionOtherModel>(ProjectTransactionOtherModel, data)));
  }

  // bds
  previewBds(data: { projectId: number; projectCommissionId: number; optionDate: string }): Observable<ProjectTransactionBdsModel[]> {
    return this.apiService
      .post(`${ProjectTransactionApiService.BDS_POINT}/preview`, data)
      .pipe(map((res: IProjectTransactionBds[]) => res.map(item => ProjectTransactionBdsModel.fromJson(item) as ProjectTransactionBdsModel)));
  }

  createBds(data: any): Observable<ProjectTransactionBonusModel> {
    return this.apiService.post(`${ProjectTransactionApiService.BDS_POINT}/setup`, data);
  }

  searchBds(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectTransactionBdsModel>> {
    return this.apiService.get(ProjectTransactionApiService.BDS_POINT, query, option).pipe(map(data => new PaginationAdapter(ProjectTransactionBdsModel, data)));
  }

  searchEngine(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectSearchEngineModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.SEARCH_ENGINE_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectSearchEngineModel, data)));
  }

  searchEngineFinance(query = {}, option?: IApiOption): Observable<PaginationAdapter<ProjectSearchEngineModel>> {
    return this.apiService
      .get(ProjectTransactionApiService.SEARCH_ENGINE_FINANCE_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ProjectSearchEngineModel, data)));
  }

  // summary global
  getSummary(id: number, query = {}, option?: IApiOption): Observable<ProjectTransactionSummaryModel> {
    return this.apiService.get(`${ProjectTransactionApiService.SUMMARY_POINT}/${id}`, query, option).pipe(map(data => ProjectTransactionSummaryModel.fromJson(data)));
  }
}
