/* ----------------------------------------------------------------------------------------------------- */
/* @ Any configuration we need from Tailwind's config file will be extracted here so we can import this
/* @ file from "config.ts" to access the extracted configuration from TypeScript
/* ----------------------------------------------------------------------------------------------------- */

// @variants redoc-tailwind-extracted-config {
// }

// @tailwind utilities;
// @apply utilities {
//   @apply red-tailwind-extracted-config {
//   }
// }
