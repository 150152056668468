import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';

export class PreviewOriginalTemplateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  templateId!: number;

  @Expose()
  templateCode!: string;

  @Expose()
  type!: string;

  @Expose()
  templateType!: string;

  @Expose()
  @Type(() => Number)
  sourceId!: number;

  @Expose()
  @Type(() => Number)
  businessUnitId!: number;
}

export class PreviewTemplateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  templateId!: number;

  @Expose()
  templateCode!: string;

  @Expose()
  type!: string;

  @Expose()
  data!: any;

  @Expose()
  templateType!: string;

  @Expose()
  @Type(() => Number)
  sourceId!: number;

  @Expose()
  @Type(() => Number)
  businessUnitId!: number;
}