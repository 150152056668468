import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Helper } from '@red/utils';
import { Expose, Transform, Type } from 'class-transformer';
import { PROJECT_PROPERTY_TYPES_OPTIONS } from '../data';
import { PROJECT_TRANSACTION_VERIFY_STATUS } from '../data/project-transaction-status.data';
import { EProjectPropertyType, EProjectTransactionStatus } from '../enums';
import { IProjectTransaction } from '../interfaces';
import { OptionDescription } from '../types';
import { CurrencyModel } from './currency.model';
import { GrossCommissionEarnedModel } from './gross-commission-earned.model';
import { ProjectCommissionMatrixModel } from './project-commission-matrix.model';
import { ProjectHICommMatrixModel } from './project-hi-commission-matrix.model';
import { ProjectTransactionBuyerInfoModel } from './project-transaction-buyer-info.model';
import { ProjectTransactionRelatedPartyModel } from './project-transaction-related-party.model';
import { ProjectModel } from './project.model';
import { TransactedTypeModel } from './transacted-type.model';
import { UnitModel } from './unit.model';
import { UpdatedByModel } from './updated-by.model';
import { groupBy } from 'lodash-es';

export class ProjectTransactionModel extends BaseModel implements IProjectTransaction {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  // @Expose()
  // currencyId?: number;

  @Expose()
  originalCurrencyId?: number;

  @Expose()
  @Type(() => CurrencyModel)
  originalCurrency?: CurrencyModel;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  projectId!: number;

  @Expose()
  @Type(() => ProjectTransactionBuyerInfoModel)
  buyer!: ProjectTransactionBuyerInfoModel;

  @Expose()
  @Type(() => ProjectModel)
  project!: ProjectModel;

  @Expose()
  // propertyType!: EProjectTransactionPropertyType;
  propertyType!: EProjectPropertyType;

  @Expose()
  landAreaSqft!: number;

  @Expose()
  landAreaSqm!: number;

  @Expose()
  unitId?: number;

  @Expose()
  @Type(() => UnitModel)
  unit?: UnitModel;

  @Expose()
  optionNo!: string;

  @Expose()
  optionDate!: string;

  @Expose()
  @Transform(({ obj, value }) => {
    if (!value) {
      let paymentGroup = groupBy(obj?.payments, i => i?.code);
      return Object.values(paymentGroup).map((i) => {
        return i[0]?.paymentDate
      });
    }
    return value
  })
  paymentDates!: string[];

  @Expose()
  payments?: {
    totalAmount: number;
    itemCode: string
    code: string;
    paymentDate: string;
  }[];

  @Expose()
  totalPaymentAmount?: number;

  @Expose()
  totalGce?: number;

  @Expose()
  commType?: string;

  @Expose()
  eotDate!: string;

  @Expose()
  projectCommissionId!: number;

  @Expose()
  @Type(() => ProjectCommissionMatrixModel)
  projectCommission!: ProjectCommissionMatrixModel;

  @Expose()
  projectCommInternationalId!: number;

  @Expose()
  @Type(() => ProjectHICommMatrixModel)
  projectCommInternational!: ProjectHICommMatrixModel;

  @Expose()
  @Default({})
  @Type(() => ProjectTransactionRelatedPartyModel)
  relatedAgent!: ProjectTransactionRelatedPartyModel;

  @Expose()
  @Default([])
  @Type(() => TransactedTypeModel)
  transactedTypes!: TransactedTypeModel[];

  @Expose()
  transactedPrice!: number;

  @Expose()
  originalTransactedPrice?: number;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  grossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  devGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  agentGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  closingAgentGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  hiGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  profomaGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  companyGrossComm!: GrossCommissionEarnedModel;

  @Expose()
  status!: `${EProjectTransactionStatus}`;

  @Expose()
  submittedBy!: {
    id: number;
    name: string;
  };

  @Expose()
  submittedAt!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: UpdatedByModel;

  @Expose()
  isReissue?: boolean;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  reissueDate?: string;

  @Expose()
  grouping?: string;

  @Expose()
  @Default([])
  batchDetails?: {
    batchId: number;
    status: string;
  }[];

  @Expose()
  batchCode!: string;

  @Expose()
  @Default({
    closingAgentAmount: 0,
    icBonusAmount: 0,
    icOverridingAmount: 0,
    otherFundAmount: 0,
    teamLeaderAmount: 0,
    bdAmount: 0,
    headHiAmount: 0,
    hiCompanyAmount: 0,
  })
  summary!: {
    closingAgentAmount: number;
    icBonusAmount: number;
    icOverridingAmount: number;
    otherFundAmount: number;
    teamLeaderAmount: number;
    bdAmount: number;
    headHiAmount: number;
    hiCompanyAmount: number;
  };

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.grossComm?.amount;
  })
  devCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.agentGrossComm?.amount;
  })
  agtCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.companyGrossComm?.amount;
  })
  companyCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.summary?.closingAgentAmount;
  })
  caCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.summary?.teamLeaderAmount;
  })
  glCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.summary?.icBonusAmount;
  })
  icBonusCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.summary?.icOverridingAmount;
  })
  icCommCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    return obj.summary?.otherFundAmount;
  })
  mfCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    if (!obj?.originalTransactedPrice || !obj?.projectCommInternational?.developerTier) return 0;
    return (obj.originalTransactedPrice * obj.projectCommInternational.developerTier) / 100;
  })
  billingPrice!: number;

  @Expose()
  metadata!: {
    admin: string;
    isFirstTimeSubmitted: boolean;
  };

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    switch (obj.status) {
      case 'draft':
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: 'Draft',
        };

      case 'rework1':
      case 'rework2':
      case 'rework3':
        return {
          className: 'status-box-danger',
          value: obj.status,
          displayName: 'Send back',
        };
      case 'submitted':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Pending verification',
        };
      case 'confirmed':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Pending approval',
        };
      case 'verified':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Pending invoice',
        };
      case 'approved':
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: 'Approved',
        };
      case 'invoiced':
        return {
          className: 'status-box-turquoise-surf',
          value: obj.status,
          displayName: 'Invoiced',
        };
      case 'completed':
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: 'Completed',
        };
      default:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: obj.status,
        };
    }
  })
  statusObj!: { className: string; value: string; displayName: string };


  get propertyTypeInfo(): OptionDescription<EProjectPropertyType> | null {
    // return this.propertyType ? PROJECT_TRANSACTION_PROPERTY_TYPE_OPTIONS[this.propertyType] : null;
    return this.project.type ? PROJECT_PROPERTY_TYPES_OPTIONS[this.project.type as EProjectPropertyType] : null;
  }

  // =============== Action Permission ===============
  @Expose()
  @Transform(({ obj }) => {
    return ['draft', 'rework1', 'rework2', 'rework3'].includes(obj.status);
  })
  canSaveAndKeepStatus!: boolean;

  get displayStatus() {
    return PROJECT_TRANSACTION_VERIFY_STATUS[this.status];
  }

  get canSubmit() {
    return PROJECT_TRANSACTION_VERIFY_STATUS[this.status].canSubmit ?? false;
  }

  get canEdit() {
    return PROJECT_TRANSACTION_VERIFY_STATUS[this.status].canEdit ?? false;
  }

  get canDelete() {
    return PROJECT_TRANSACTION_VERIFY_STATUS[this.status].canDelete ?? false;
  }

  get canRefresh() {
    return [
      EProjectTransactionStatus.draft,
      EProjectTransactionStatus.submitted,
      EProjectTransactionStatus.confirmed,
      EProjectTransactionStatus.verified,
      EProjectTransactionStatus.rework1,
      EProjectTransactionStatus.rework2,
      EProjectTransactionStatus.rework3,
    ].includes(this.status as EProjectTransactionStatus);
  }
  // =============== End Action Permission ===============

  // =============== Calculating Comm ===============
  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    if (!obj.devGrossComm?.amount || obj.projectCommInternational?.headHi?.value === 0 || !obj?.projectCommInternational?.developerTier) return 0;
    return Helper.round((obj?.devGrossComm?.amount * obj?.projectCommInternational?.headHi?.value) / obj?.projectCommInternational?.developerTier, 2);
  })
  internationalHeadHiCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    if (!obj.devGrossComm?.amount || obj.projectCommInternational?.bd?.value === 0 || !obj?.projectCommInternational?.developerTier) return 0;
    return Helper.round((obj.devGrossComm?.amount * obj.projectCommInternational?.bd?.value) / obj?.projectCommInternational?.developerTier, 2);
  })
  internationalBdsCommAmt!: number;

  @Expose()
  @Transform(({ obj }: { obj: IProjectTransaction }) => {
    if (!obj.transactedPrice || obj.projectCommInternational?.hiProfit?.value === 0) return 0;
    return Helper.round((obj.transactedPrice * obj.projectCommInternational?.hiProfit?.value) / 100, 2);
  })
  internationalHiProfitCommAmt!: number;

  @Expose()
  internationalRef!: string;
  // =============== End Calculating Comm ===============
}

export class ProjectTransactionAgentModel extends ProjectTransactionModel {
  @Expose()
  role!: 'agent' | 'ic';

  get canViewRejectReason() {
    return (['rework1', 'rework2'].includes(this.status) && this.role === 'ic') ?? false;
  }

  get canAgentEdit() {
    return (PROJECT_TRANSACTION_VERIFY_STATUS[this.status].canAgentEdit && this.role === 'ic') ?? false;
  }

  get canAgentDelete() {
    return (PROJECT_TRANSACTION_VERIFY_STATUS[this.status].canAgentDelete && this.role === 'ic') ?? false;
  }

  get canAgentSubmit() {
    return (PROJECT_TRANSACTION_VERIFY_STATUS[this.status].canAgentSubmit && this.role === 'ic') ?? false;
  }
}

