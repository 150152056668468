import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EPaymentDirectStatus } from '../enums';
import { IBase, IDirectDebitPayment, IDirectDebitPaymentPostingAccount, IDirectDebitPaymentRecord, IInvoicePayable, IMemoPayable, ITaxInvoice, ITaxInvoiceRecord } from '../interfaces';
import { LedgerAccountModel } from './ledger-account.model';
import { MemoPayableModel } from './memo-payable.model';
import { ReceiptModel } from './receipt.model';
import { UpdatedByModel } from './updated-by.model';
import { ContactModel } from './contact.model';

export type IInvoiceFullField = Required<IBase & ITaxInvoice & { currency: string; code: string }>;
export type ITaxInvoiceDetailRequired = Required<ITaxInvoiceRecord> & {
  id: number;
  taxInvoice: IInvoiceFullField;
  total: number;
  paidAmount: number;
  balanceDue: number;
  updatedAt: string;
};

export function paymentMemoDetailToMemoPayableModel(paymentMemoDetail?: ITaxInvoiceDetailRequired, item?: IDirectDebitPaymentRecord): IMemoPayable | undefined {
  if (!paymentMemoDetail) {
    return undefined;
  }

  const invoice = paymentMemoDetail.taxInvoice
    ? Object.assign(paymentMemoDetail.taxInvoice, {
      id: paymentMemoDetail.taxInvoiceId,
      code: paymentMemoDetail.taxInvoice.code ?? paymentMemoDetail.taxInvoice.invoiceNumber,
      contactId: item?.contactId,
      contact: item?.contact,
    })
    : null;

  const isCN = item?.paymentMemoType === 'CustomerCreditNote';
  const id = isCN ? item.invoiceId : paymentMemoDetail.id;
  const type = isCN ? 'CN' : 'VI';

  const val: IMemoPayable = {
    id,
    recordId: item?.id,
    itemCode: paymentMemoDetail.itemCode,
    description: '',
    type,
    total: paymentMemoDetail.total,
    amount: Number(item?.amount),
    paidAmount: paymentMemoDetail.paidAmount,
    balanceDue: paymentMemoDetail.balanceDue,
    invoiceId: paymentMemoDetail.taxInvoiceId,
    invoice,
    receipt: item?.receipt,
    updatedAt: paymentMemoDetail.updatedAt,
    posting: {
      accountCode: invoice?.contact.paidAccount?.code,
      accountId: invoice?.contact.paidAccount?.id,
      accountName: invoice?.contact.paidAccount?.name
    }
  };
  return MemoPayableModel.fromJson(val);
}

export class DirectDebitPaymentPostingAccountModel extends BaseModel implements IDirectDebitPaymentPostingAccount {
  @Expose()
  accountCode!: string;

  @Expose()
  accountId!: number;

  @Expose()
  accountName!: string;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.accountCode || ''} ${obj.accountName || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;

}

export class DirectDebitPaymentRecordModel extends BaseModel implements IDirectDebitPaymentRecord {
  @Expose()
  contactId!: number;

  @Expose()
  contact!: ContactModel;

  @Expose()
  invoiceId!: number;

  @Expose()
  receiptId!: number;

  @Expose()
  @Type(() => ReceiptModel)
  receipt!: ReceiptModel;

  @Expose()
  paymentMemoDetailId!: number;

  @Expose()
  paymentMemoType!: 'SupplierInvoice' | 'CustomerCreditNote';

  @Expose()
  @Transform(params => paymentMemoDetailToMemoPayableModel(params.value, params.obj))
  paymentMemoDetail!: MemoPayableModel;

  @Expose()
  amount!: number;

  @Expose()
  @Type(() => Date)
  updatedAt!: Date;

  @Expose()
  id!: number;

  @Expose()
  paymentDirectId!: number;

  @Expose()
  @Type(() => DirectDebitPaymentPostingAccountModel)
  posting!: DirectDebitPaymentPostingAccountModel;

  @Expose()
  errorMessage!: string;

  @Expose()
  errorCode!: string;

  @Expose()
  supplierInvoiceCode!: string;
}

export class DirectDebitPaymentModel extends BaseModel implements IDirectDebitPayment {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  bankReturnFile!: string;

  @Expose()
  transactionCode!: string;

  @Expose()
  // @Type(() => Date)
  // submissionDate!: Date;
  submissionDate!: string;

  @Expose()
  // @Type(() => Date)
  // valueDate!: Date;
  valueDate!: string;

  @Expose()
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => DirectDebitPaymentRecordModel)
  items!: DirectDebitPaymentRecordModel[];

  @Expose()
  @Transform(({ obj }: { obj: IDirectDebitPayment }) => {
    switch (obj.status) {
      case 'Selected':
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: 'Selected',
        };
      case 'Assigned':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Assigned',
        };
      case 'Submitted':
        return {
          className: 'status-box-vivid-tangelo',
          value: obj.status,
          displayName: 'Submitted',
        };
      case 'EmailSent':
        return {
          className: 'status-box-warning',
          value: obj.status,
          displayName: 'Email Sent',
        };
      case 'Posted':
        return {
          className: 'status-box-success',
          value: obj.status,
          displayName: 'Posted',
        };
      default:
        return {
          className: 'status-box-default',
          value: obj.status,
          displayName: obj.status,
        };
    }
  })
  statusObj!: { className: string; value: string; displayName: string };

  @Expose()
  status!: EPaymentDirectStatus;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  get canConfirmEmail(): boolean {
    return [EPaymentDirectStatus.Assigned, EPaymentDirectStatus.Submitted].includes(this.status);
  }

  get canPostPayment(): boolean {
    return this.status === EPaymentDirectStatus.EmailSent;
  }

  get canEdit(): boolean {
    return this.status === EPaymentDirectStatus.Selected;
  }
}
