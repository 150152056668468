import { ETransferEmplacementType } from '../enums/transfer-emplacement.enum';
export const TRANSFER_EMPLACEMENT_TYPES = [
  {
    id: ETransferEmplacementType.transfer,
    name: 'Transfer',
  },
  {
    id: ETransferEmplacementType.emplacement,
    name: 'Emplacement',
  },
  {
    id: ETransferEmplacementType.auto,
    name: 'Auto step-up',
  },
  {
    id: ETransferEmplacementType.new,
    name: 'New Agent',
  },
  {
    id: ETransferEmplacementType.resigned,
    name: 'Resigned',
  },
  {
    id: ETransferEmplacementType.migration,
    name: 'Migration',
  },
  {
    id: ETransferEmplacementType.rejoin,
    name: 'Re-join',
  },
] as const;
